@import 'src/styles/mixins.scss';

.root {
	background-color: var(--darkColor);
	display: block;
	user-select: none;
	z-index: 20;
	padding-right: 10px;
	max-height: 59px;
	&:hover {
		@include hover-supported {
			cursor: pointer;
		}
	}
}

.figure,
.strong {
	display: inline-block;
	vertical-align: top;
	position: relative;
}

.figure {
	width: var(--headerHeight);
	height: 58px;
}

.svg {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -15px 0 0 -15px;
}

.strong {
	color: var(--whiteColor);
	font-weight: 900;
	text-transform: uppercase;
	font-size: 21px;
	line-height: 62px;
	letter-spacing: 0.03em;
	@media (max-width: 450px) {
		font-size: 18px;
	}
}

.header {
	width: 100%;
	background-color: var(--darkColor);
	font-size: 0;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--borderColorDarkTheme);
}

.donateBtn {
	padding: 4px 20px;
	height: auto;
	align-self: center;
	border-radius: 110px;
	margin-right: 15px;
	span {
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.48px;
		white-space: normal;
		text-align: center;
		max-width: min-content;
	}
}
