@import 'src/styles/mixins.scss';

.root {
	background-color: var(--darkColor);
}

.copyright {
	display: block;
	color: var(--textColorFooter);
	padding: 30px 20px;
	font-size: 15px;
	line-height: 20px;
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
	@media screen and (max-width: 1023px) {
		padding: 30px 10px;
	}
	@include --tablet-horizontal-max {
		font-size: 13px;
		line-height: 15px;
		padding-top: 10px;
	}
}

.copyright a {
	color: var(--textColorFooter);
	font-size: 15px;
	line-height: 20px;
	transition: color 0.3s ease;

	&:hover {
		color: var(--whiteColor);
	}

	@include --tablet-horizontal-max {
		font-size: 13px;
		line-height: 15px;
	}
}
