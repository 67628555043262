@import 'src/styles/mixins.scss';

.title {
	margin: 0;
	font-weight: 700;
	font-size: 60px;
	line-height: 65px;
	width: 100%;
	color: var(--darkColor);
	margin-block-start: 0;
	margin-block-end: 0;
	// margin-top: 20px;
	// text-align: center;
	max-width: 840px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;

	@media screen and (max-width: 1200px) {
		max-width: 65vw;
		margin-left: 30px;
		font-size: 48px;
		line-height: 60px;
	}

	@media screen and (max-width: 1024px) {
		font-size: 5vw;
		line-height: 7vw;
		max-width: 68vw;
	}
}

.brMobile {
	display: none;
	@media screen and (max-width: 1000px) {
		display: block;
	}
}
.subtitle {
	font-weight: 500;
	font-size: 35px;
	line-height: 37px;
	width: 380px;
	margin-left: 150px;
	color: var(--darkColor);
	margin-top: 75px;
	@media screen and (max-width: 1024px) {
		margin-left: 40px;
	}
	@media screen and (max-width: 980px) {
		margin-left: 34px;
	}

	@media screen and (max-width: 780px) {
		margin-left: 0px;
		font-size: 24px;
		line-height: 30px;
		max-width: 197px;
	}
}

.magazineCover {
	position: absolute;
	transform: rotate(-12.55deg);
	filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.4));
	width: 300px;
	bottom: -115px;
	right: 95px;
	object-fit: contain;
	@media screen and (max-width: 1030px) {
		right: 35px;
	}

	@media screen and (max-width: 780px) {
		right: -20px;
		bottom: 20px;
		width: 220px;
	}

	@media screen and (max-width: 430px) {
		width: 185px;
		right: -50px;
		bottom: 45px;
	}
	@media screen and (max-width: 360px) {
		right: -75px;
	}
	@media screen and (max-width: 340px) {
		bottom: 60px;
		width: 173px;
	}
}

.cover {
	object-fit: contain;
	width: 400px;
	height: 100%;
	position: absolute;
	right: -35px;
	bottom: -150px;

	@media screen and (max-width: 1200px) {
		right: -60px;
	}
}

@include --mobile {
	.title {
		position: relative;
		top: unset;
		transform: unset;
		max-width: unset;
		text-align: center;
		margin: 0;
		margin-top: 50px;
		padding-bottom: 50vw;
		font-size: 6.5vw;
		line-height: 7.8vw;
	}
	.cover {
		right: 50%;
		transform: translateX(50%);
		width: 50vw;
		height: auto;
		bottom: unset;
		top: 27vw;
	}
	.brMobile {
		display: none;
	}
}
