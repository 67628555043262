:global {
	.Toastify__toast {
		border-radius: 6px;
		border: 2px solid var(--mainColor);
		max-width: 414px;
		@media (max-width: 480px) {
			max-width: unset;
		}
	}

	.Toastify__toast-body {
		font-family: var(--sansSerif);
		font-size: 18px;
		font-weight: 400;
		line-height: 22px;
		z-index: 9999999999;
	}

	.Toastify__toast-container {
		max-width: 414px !important;
		z-index: 1111111 !important;
		@media (max-width: 480px) {
			max-width: unset !important;
		}
	}

	.Toastify__toast-icon {
		display: none !important;
	}

	.Toastify__close-button {
		top: 0;
		bottom: 0;
		margin: auto 0;
		right: 12px;
		opacity: 1 !important;
		svg {
			height: 20px !important;
			width: 20px !important;
			fill: var(--mainColor) !important;
		}
	}

	.Toastify__toast--error {
		background-color: var(--whiteColor) !important;
		color: #bf381d !important;
		border: 2px solid #bf381d !important;
		.Toastify__close-button svg {
			fill: #bf381d !important;
		}
	}
	.Toastify__toast--success {
		color: var(--mainColor) !important;
		.Toastify__close-button svg {
			fill: var(--mainColor) !important;
		}
	}
	.Toastify__toast--default > .Toastify__toast-body {
		background-color: var(--whiteColor) !important;
		color: var(--mainColor);
	}
}
