@import 'src/styles/mixins.scss';

.root {
	min-height: 100vh;
	background: linear-gradient(180deg, #f7f7f7 40%, #ffed26 100%);
	display: flex;
	justify-content: center;
}

.container {
	max-width: 1173px;
	width: 100%;
	min-height: 100vh;
	background-color: var(--whiteColor);
	padding: 20px 0 85px;
	margin: 0 15px;

	@include --mobile {
		padding-bottom: 0;
	}
}

.content {
	max-width: 730px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
	@include --mobile {
		margin-bottom: 50px;
	}
}
