@import 'src/styles/mixins.scss';

.root {
	background-color: var(--darkColor);
	display: flex;
	justify-content: center;
	padding: 60px 15px;
}

.container {
	width: 100%;
	max-width: 1365px;
}

.text {
	border-top: 1px solid var(--secondColor);
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	margin-block-start: 0;
	margin-block-end: 0;
	padding-top: 15px;
	color: var(--secondColor);
	a {
		font-weight: 700;
		color: var(--secondColor);
		&:hover {
			@include hover-supported {
				color: var(--whiteColor);
			}
		}
	}
}

.navigation {
	display: flex;
	margin: 40px auto;
	justify-content: space-between;

	@include --desktop-max {
		flex-direction: column;
		align-items: center;
		&_column {
			display: flex;
			flex-direction: column;
		}
	}
}

.navigation_regulation {
	@include --desktop-max {
		margin-bottom: 15px;
	}
}
