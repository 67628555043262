@import 'src/styles/mixins.scss';

.button {
	height: 39px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	background-color: var(--mainColor);
	padding: 0 30px;
	span {
		color: var(--darkColor);
		text-transform: uppercase;
	}
}

.yellow {
	background-color: var(--donateColor);
}