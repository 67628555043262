@import 'src/styles/mixins.scss';

:root {
	--serif: 'Noto Serif', serif;
	--sansSerif: 'Roboto Flex', sans-serif;

	--mainColor: #00ace5;
	--greenColor: #0e8e7e;
	--secondaryColor: #bf381d;
	--darkColor: #212226;
	--textColor: #494a4d;
	--textColorFooter: #8b8c8c;
	--secondColor: #a6a6a6;
	--whiteColor: #ffffff;
	--fieldColor: #f0f1f2;
	--stripesColor: #e3e4e5;
	--lightDarkColor: #979899;
	--borderColor: #e6e6e6;
	--borderColorDarkTheme: #434343;
	--borderColorWhiteTheme: #f0f0f0;
	--lightGray: #d5d9e0;
	--lighterSecond: #f7f7f7;
	--donateColor: #ffed26;
	--lightestSecond: #e7e7e7;
	--boxShadowColor: rgba(205, 205, 205, 0.25);
	--transparentWhiteColor: rgba(255, 255, 255, 0.1);
	--transparentDarkColor: rgba(0, 0, 0, 0.1);
	--gradient: linear-gradient(90deg, rgba(0, 172, 229, 1) 0%, rgba(32, 119, 187, 1) 50%);

	--minWidth: 320px;

	--headerHeight: 60px;
	--smallHeaderHeight: 60px;

	--desktopMaxWidth: 1160px;
	--tabletHorizontalMaxWidth: 920px;
	--tabletVerticalMaxWidth: 680px;

	--desktopColumn: 60px;
	--tabletColumn: 40px;

	--desktopGutter: 40px;
	--tabletGutter: 40px;

	--blackDots: url('data:image/gif;base64,R0lGODlhBQAFAPAAACgoLQAAACH5BAEAAAEALAAAAAAFAAUAAAIFRI6piwUAOw==');
	--grayDots: url('data:image/gif;base64,R0lGODlhBQAFAPAAAEtNUAAAACH5BAEAAAEALAAAAAAFAAUAAAIFRI6piwUAOw==');
	--blueDots: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiIHZpZXdCb3g9IjAgMCA1IDUiPjxjaXJjbGUgZmlsbD0iIzAwYWNlNSIgY3g9IjIuNSIgY3k9IjIuNSIgcj0iLjUiLz48L3N2Zz4=');
}

html,
body {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	font-family: var(--sansSerif);
	min-width: var(--minWidth);
	background: var(--lighterSecond);
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.transition-all {
	& * {
		transition: 0.3s all linear;
	}
}

.ng-root {
	max-width: 100%;
	height: 100%;
	background-color: var(--lighterSecond);
}

figure {
	margin: 0;
}

a {
	text-decoration: none;
	outline: 0;
}

button {
	background-color: transparent;
	border-radius: 0;
	border: 0;
	box-shadow: none;
	outline: 0;
	padding: 0;
	display: block;
	cursor: pointer;
}

input {
	display: block;
	outline: 0;
	box-shadow: none;
	border: 0;
	background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='search']::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.ng-state {
	display: none;
}

/**
 * Legacy stuff
 */

.legacy-browsers {
	display: none;
	text-align: center;
	position: absolute;
	font-weight: 900;
	font-size: 30px;
	line-height: 30px;
	width: 600px;
	height: 350px;
	margin: calc(-1 * (350px / 2)) 0 0 calc(-1 * (600px / 2));
}

.legacy-browsers__title {
	margin-bottom: 50px;
}

.legacy-browsers__link {
	display: block;
	color: var(--mainColor);
}

.no-flexbox .no-flexboxlegacy .no-flexboxtweener {
	.legacy-browsers {
		display: block;
	}

	.ng-root {
		display: none;
	}
}
