@import 'src/styles/mixins.scss';

.root {
	background-color: var(--donateColor);
	position: relative;
	border-top: 1px solid var(--donateColor);
	overflow: hidden;

	&_background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-size: 140%;
		background-position: center center;
		opacity: 0.3;
	}
	@media screen and (max-width: 1024px) {
		&_background {
			background-size: 340%;
		}
	}
	@include --mobile {
		&_background {
			background-size: 440%;
		}
	}
}

.container {
	position: relative;
	z-index: 10;
	max-width: 1005px;
	width: 100%;
	margin: 0 auto;
	height: 100%;
	padding: 45px 0;
	overflow: hidden;

	@media screen and (max-width: 1030px) {
		max-width: 900px;
	}
	@media screen and (max-width: 980px) {
		max-width: unset;
		margin: 0;
		padding: 45px;
	}
	@media screen and (max-width: 780px) {
		margin: 0 auto;
		padding: 25px;
	}
}

.promo {
	height: 500px;
	.container {
		padding: 0;
		overflow: visible;
		max-width: 1173px;

		// @media screen and (max-width: 980px) {
		// 	padding: 0 20px;
		// 	padding-top: 45px;
		// }

		// @media screen and (max-width: 780px) {
		// 	max-width: 450px;
		// 	overflow: unset;
		// }

		@media screen and (max-width: 1220px) {
			margin: 0;
			padding: 0 15px;
		}
	}
}

.header {
	h1 {
		text-align: center;
		color: var(--darkColor);
		font-weight: 700;
		font-size: 60px;
		line-height: 65px;
	}

	h2 {
		text-align: center;
		color: var(--darkColor);
		font-size: 35px;
		font-weight: 600;
		line-height: 37px;
	}

	@include --mobile {
		h1 {
			font-size: 10vw;
			line-height: 11vw;
		}

		h2 {
			font-size: 6vw;
			line-height: 6.6vw;
		}
	}
}

@include --mobile {
	.promo {
		height: auto;
		.container {
			padding: 0;
		}
	}
}
