@import 'src/styles/mixins.scss';

.root {
	background-color: var(--darkColor);
	position: relative;
	height: 375px;

	&_background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-size: 140%;
		background-position: center center;
		opacity: 0.1;
		@media screen and (max-width: 1024px) {
			background-size: 340%;
		}
	}
	@include --mobile {
		height: 190px;
	}
}
