@mixin --mobile-max {
	@media screen and (max-width: 509px) {
		@content;
	}
}

@mixin --desktop-max {
	@media screen and (max-width: 1023px) {
		@content;
	}
}

@mixin --desktop-container-only {
	@media (min-width: 1023px) and (max-width: 1365px) {
		@content;
	}
}

@mixin --container-max {
	@media screen and (max-width: 1365px) {
		@content;
	}
}

@mixin --tablet-only {
	@media screen and (min-width: 721px) and (max-width: 1200px) {
		@content;
	}
}

@mixin --tablet-vertical-min {
	@media screen and (min-width: 721px) {
		@content;
	}
}

@mixin --tablet-vertical-only {
	@media screen and (max-width: 960px) and (min-width: 721px) {
		@content;
	}
}

@mixin --tablet-vertical-max {
	@media screen and (max-width: 960px) {
		@content;
	}
}

@mixin --tablet-horizontal-min {
	@media screen and (min-width: 961px) {
		@content;
	}
}

@mixin --tablet-horizontal-only {
	@media screen and (max-width: 1200px) and (min-width: 961px) {
		@content;
	}
}

@mixin --mobile {
	@media screen and (max-width: 720px) {
		@content;
	}
}

@mixin --desktop-min {
	@media screen and (min-width: 1200px) {
		@content;
	}
}
@mixin --vote-510px {
	@media screen and (max-width: 510px) {
		@content;
	}
}

@mixin --vote-min {
	@media screen and (max-width: 414px) {
		@content;
	}
}

@mixin --tablet-horizontal-max {
	@media screen and (max-width: 1200px) {
		@content;
	}
}

@mixin hover-supported {
	@media (hover: hover) {
		@content;
	}
}

@mixin novaya-constructor-theme {
	:global(.novaya-constructor-theme) & {
		@content;
	}
}
@mixin novaya-media-theme {
	:global(.novaya-media-theme) & {
		@content;
	}
}