@import 'src/styles/mixins.scss';

.root {
	position: sticky;
	top: 0;
	left: 0;
	transition: top 0.3s;
	z-index: 1299;
	height: var(--headerHeight);

	@include --mobile {
		height: var(--smallHeaderHeight);
	}
}

.hidden {
	top: -61px;
}
